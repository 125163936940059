import React from "react";
import Tippy from "@tippyjs/react";
import ReactHtmlParser from "react-html-parser";

const TippyIcon = props => {
	return (
		<>
			{props.content ? (
				<Tippy
					animation="fade"
					theme="custom"
					disabled={!props.content || props.content === ""}
					content={props.content}
					// content={ReactHtmlParser(props.content)}
					placement={props.placement ? props.placement : "bottom"}
					arrow={true}
					// onShow={instance => {
					// 	setTimeout(() => {
					// 		instance.hide();
					// 	}, 3000);}}
					className={`custom-tippy z-10000 ${props.className ? props.className : ""}`}
				>
					{/* Tooltip icon */}
					{props.children}
				</Tippy>
			) : (
				props.children
			)}
		</>
	);
};

export default TippyIcon;
