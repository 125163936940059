import React from "react";
import Link from "next/link";
import Image from "next/image";
import { useContext } from "react";
import eventContext from "../../../../contexts/EventContext";
import moment from "moment";
import { convertTimezone, replaceAll } from "../../../../helpers/common";
import TippyIcon from "../../../../components/TippyIcon";
import EventTimer from "./EventTimer";
import Loading from "../../../../components/Loading";
import _ from "lodash";
import dynamic from "next/dynamic";
import ReactHtmlParser from "react-html-parser";
import { addAttributesToLinks } from "../../../../helpers/utils";
import { SOLD_OUT } from "../../../UseCases/Constants";
import { EXPIRED } from "../../../UseCases/Constants";
const EventOrganiserBox = dynamic(() => import("./EventOrganiserBox"));



const EventDetailsMinimalistic = ({
	isMobile,
	isTicketSoldOut,
	ticketData,
	scrollToSection,
	hasCoupons,
	newQuery,
}) => {
	const { eventData } = useContext(eventContext);

	const tillDate = moment
		.utc(`${eventData.start_date} ${eventData.start_time}`)
		.local()
		.valueOf();
	let now = moment().valueOf();

	let event_start_timestamp = `${eventData.start_date} ${eventData.start_time}`;
	let event_end_timestamp = `${eventData.end_date} ${eventData.end_time}`;
	const localTimezone = moment.tz.guess();

	const getDate = (date, format) => {
		return moment(convertTimezone(date, eventData.time_zone)).format(format);
	};



	const date = {
		start_month: moment.utc(event_start_timestamp).local().format("MMM Do"),
		end_month: moment.utc(event_end_timestamp).local().format("MMM Do"),
		start_day: moment.utc(event_start_timestamp).local().format("ddd"),
		end_day: moment.utc(event_end_timestamp).local().format("ddd"),
		start_year: moment.utc(event_start_timestamp).local().format("YYYY"),
		end_year: moment.utc(event_end_timestamp).local().format("YYYY"),
		start_time: moment.utc(event_start_timestamp).local().format("h:mm A"),
		end_time: moment.utc(event_end_timestamp).local().format("h:mm A"),
		timezone: moment.tz(event_end_timestamp, localTimezone).format("z"),
	};

	const getTimeZone = () => {
		if (date.timezone.includes("+")) {
			return `GMT${date.timezone}`;
		} else {
			return date.timezone;
		}
	};

	const isSameDate = (dateValue) => {
		if (
			dateValue.start_month == dateValue.end_month &&
			dateValue.start_year == dateValue.end_year
		) {
			return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time
				} to ${dateValue.end_time} ${getTimeZone()}`;
		} else {
			return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time
				} - ${dateValue.end_month}, ${dateValue.end_year} ${dateValue.end_time
				} ${getTimeZone()}`;
		}
	};

	const isSameDateWithOutYear = (dateValue) => {
		if (dateValue.start_month == dateValue.end_month) {
			return `${dateValue.start_month} ${dateValue.start_time} to ${dateValue.end_time} ${getTimeZone()}`
		} else {
			return `${dateValue.start_month} ${dateValue.start_time} - ${dateValue.end_month} ${dateValue.end_time} ${getTimeZone()}`
		}
	}

	const checkIfDisabled = (buttonText) => {
		if (buttonText === "Sold Out" || buttonText === "Expired") {

			return "disabled-btn-title";
		} else return "";
	};



	const checkVenue = () => {
		if (eventData.venue && eventData.latitude && eventData.longitude) {
			return (
				<TippyIcon content="View on google maps">
					<a
						className="min-link-text"
						href={`https://www.google.com/maps/search/?api=1&query=${eventData.venue.replace(
							/ /g,
							"+",
						)}`}
						target="_blank"
					>
						{eventData.venue}
					</a>
				</TippyIcon>
			);
		} else if (eventData.venue && !eventData.latitude && !eventData.longitude) {
			return eventData.venue;
		} else if (eventData.venue) return "Yet to be decided";
	};

	return (
		<>
			<section
				className={`p-sm-2 p-0 ${!isMobile ? "position-sticky" : ""} m-0`}
				style={{ top: "0px" }}
			>
				<div className="event-details-container bg-white p-3">
					<h1 className="event-details-title mb-3">{eventData.name}</h1>
					<div className="d-flex mb-3 mb-sm-4 justify-content-between justify-content-sm-between d-sm-flex flex-column flex-sm-row gap-2">
						<p className=" col-md-6 mb-0 d-flex gap-2 align-items-center" style={{ color: '#000000', fontSize: "14px" }}>
							<i

								className={`${eventData.is_virtual
									? "fa-solid fa-video me-sm-2"
									: " globe-icon me-sm-2"
									} `}


								aria-hidden="true"
							></i>{" "}
							{!eventData.is_virtual ? "In-person" : "Online"}
						</p>
						<p className="col-md-6 mb-0 d-flex gap-2 align-items-center" style={{ color: '#000000', fontSize: "14px" }}>
							<i className="ticket-icon me-sm-2"></i>
							{eventData.is_free ? "Free" : "Paid"}
						</p>
					</div>
					<div className={tillDate > now ? "mb-3" : "mb-0"}>
						{!eventData.is_virtual && eventData.venue && (
							<div className="d-sm-block" style={{ fontSize: '15px', color: '#000000', fontSize: "14px" }}>
								<p className="mb-2">
									<strong>Venue</strong>: {checkVenue()}
								</p>
								{eventData.additional_venue_details_rich_text && (
									<p className="my-3">
										{ReactHtmlParser(
											addAttributesToLinks(eventData.additional_venue_details_rich_text.replaceAll(
												'style="font-family: Hind, sans-serif;"',
												"",
											)),
										)}
									</p>
								)}
							</div>
						)}
						{eventData.is_virtual && eventData.event_live_link && (
							<p className="mb-1">
								<strong>Event Live Link</strong> :{" "}
								<a
									className="min-link-text"
									href={eventData.event_live_link}
									target="_blank"
								>
									Open streaming website
								</a>
							</p>
						)}
						<p className="my-2 mt-3 d-flex " style={{ fontSize: '15px', fontWeight: '400', color: '#000000' }}>
							<p className="" style={{ fontWeight: '600' }}></p> <span className="d-none d-sm-block">{isSameDate(date)}</span> <span style={{ fontSize: '14px', fontWeight: '500' }} className="d-block d-sm-none">{isSameDateWithOutYear(date)}</span>
						</p>
						{/* <p className='mb-1'><strong>End time</strong> : Jul 27, 10:00PM IST</p> */}
					</div>
					{tillDate > now && (
						<div className="mb-2">
							<EventTimer />
						</div>
					)}
				</div>
				{isMobile && (ticketData?.length > 0 || hasCoupons) && (
					<Link
						href={{
							pathname: `/checkout/${eventData.event_url}`,
							query: {
								...newQuery,
							},
						}}
						passHref
					>


						<button className={`minimalistic-btn buy-btn w-100 mt-3 ${checkIfDisabled(isTicketSoldOut)}`}>
							{isTicketSoldOut}

						</button>
					</Link>
				)}
				{!isMobile && (ticketData?.length > 0 || hasCoupons) && (
					<Link
						href={{
							pathname: `/checkout/${eventData.event_url}`,
							query: {
								...newQuery,
							},
						}}
						passHref
					>
						<button className={`minimalistic-btn w-100 mt-3 ${checkIfDisabled(isTicketSoldOut)}`}>
							{isTicketSoldOut}
						</button>
					</Link>
				)}
				{eventData.event_website && (
					<a
						href={eventData.event_website}
						className="minimalistic-btn sec-btn w-100 official-web text-center mt-3 mb-3"
						target="_blank"
					>
						Official Website
						<i
							className="fa fa-external-link fa-lg website-icon"
							aria-hidden="true"
						></i>
					</a>
				)}
				<div className="mt-2">
					{!isMobile && <EventOrganiserBox eventData={eventData} />}
				</div>
			</section>
			<style jsx>{`

		  a{
			cursor: pointer !important;
		  }
          .event-details-container {
            border: 0.8px solid #cacaca;
            border-radius: 8px;
            word-break: break-word;
          }
          .event-details-title {
            font-size: 1.625rem;
            font-weight: 800;
			color: #161616;
          }
		  .min-link-text{
			cursor:pointer !important;
			}
		  .disabled-btn-title {
			background: #a09f9f !important;
			cursor: not-allowed;
			pointer-events: none;
          }
          i {
            font-size: 1rem;
          }
          .official-web {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .website-icon {
            margin-left: 10px;
          }
		  .globe-icon{
			background: url("/img/Geography.svg");
			height: 21px;
			width: 21px;
		  }
		  .ticket-icon{
			background: url("/img/new-ticket.svg");
			height: 21px;
			width: 21px;
		  }
		  .calender-icon{
			background: url("/img/Calendar-icon.svg");
			height: 21px;
			width: 21px;
		  }
          @media (min-width: 992px) and (max-width: 1368px) {
            .event-details-title {
              font-size: 1.4375rem;
              font-weight: 800;
            }
            p {
              font-size: 0.875rem;
            }
          }


			@media only screen and (max-width: 576px) {
				.event-details-title {
					font-size: 18px;
					line-height: 27px;
					font-weight: 700;
				}
				.min-link-text{
					font-size:15px !important;
				}
				.sec-btn , .buy-btn{
					font-size:20px;
					font-weight:600;
				}
				.official-web {
					font-size: 18px !important;
					font-weight: 500 !important;
				
				}
				.minimalistic-btn{
					border-radius:5px !important;
				}
			}

        `}
			</style>


		</>
	);
};

export default EventDetailsMinimalistic;